<template>
  <v-row class="app-preview">
    <v-col
      cols="12"
      md="9"
    >
      <v-card class="app-quotation-editable">
        <!-- Header -->
        <div class="py-5 px-5">
          <Letterhead :width="'100%'" />

          <div class="d-flex flex-wrap justify-space-between mt-9">
            <!-- Date -->
            <div class="d-flex align-center">
              <span class="me-2 font-weight-medium text--primary">Date Issued: {{ quotation.created_at }}</span>
            </div>

            <!-- Quotation number -->
            <div
              class="d-flex align-center justify-end"
              :class="{ 'mt-4': $vuetify.breakpoint.xsOnly }"
            >
              <div>
                <v-select
                  v-model="quotation.branch_id"
                  :items="branchOptions"
                  item-value="id"
                  item-text="name"
                  single-line
                  outlined
                  dense
                  hide-details
                  clearable
                  placeholder="Select Branch"
                  class="quotation-add-branch"
                  :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                ></v-select>
                <RequestHint
                  v-if="request && request.branch && !isApprovedRequest"
                  :is-attr-change="quotation.branch_id !== request.branch.id"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="request.branch.name"
                />
              </div>
            </div>
          </div>
        </div>

        <v-divider></v-divider>

        <!-- Customer Details -->
        <div class="py-5 px-5">
          <div class="mb-3 d-flex">
            <div class="px-3 flex-grow-1 font-weight-semibold">
              <v-row>
                <!-- Customer Name -->
                <v-col
                  cols="12"
                  sm="6"
                  :class="{ 'pl-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                >
                  <p class="font-weight-semibold payment-details-header">
                    Quoted To:
                  </p>
                  <v-text-field
                    v-model="quotation.company_name"
                    single-line
                    dense
                    outlined
                    hide-details
                    placeholder="Company Name"
                    :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                  ></v-text-field>
                  <RequestHint
                    v-if="request && !isApprovedRequest"
                    :is-attr-change="quotation.company_name !== request.company_name"
                    :is-auditor-respond="auditorDisabled"
                    :is-request-void="isRequestVoid"
                    :hint="request.company_name"
                  />
                </v-col>
              </v-row>
            </div>
          </div>
        </div>

        <v-divider></v-divider>

        <!-- Quotation Details -->
        <div class="py-5 px-5">
          <div class="mb-3 d-none d-md-flex">
            <div class="px-3 flex-grow-1 font-weight-semibold">
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <span>Description</span>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <span>Amount</span>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <span>Summable Amount</span>
                </v-col>
              </v-row>
            </div>
            <div class="header-spacer"></div>
          </div>

          <!-- Insurance Product Type -->
          <v-card
            outlined
            class="d-flex pa-3"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="quotation.insurance_product_id"
                  :items="insuranceProductOptions"
                  item-value="id"
                  item-text="name"
                  dense
                  outlined
                  hide-details="auto"
                  placeholder="Select Insurance Product Type"
                  :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                ></v-select>
                <RequestHint
                  v-if="request && request.insurance_product && !isApprovedRequest"
                  :is-attr-change="quotation.insurance_product_id !== request.insurance_product.id"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="request.insurance_product.name"
                />
              </v-col>
            </v-row>
          </v-card>

          <!-- Car Registration Number -->
          <v-card
            v-if="isMotorInsurance"
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="quotation.car_registration_number"
                  dense
                  outlined
                  hide-details="auto"
                  placeholder="Car Registration Number"
                  :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                ></v-text-field>
                <RequestHint
                  v-if="request && !isApprovedRequest"
                  :is-attr-change="quotation.car_registration_number !== request.car_registration_number"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="request.car_registration_number"
                />
              </v-col>
            </v-row>
          </v-card>

          <!-- Insurance -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="quotation.insurance_id"
                  :items="insuranceOptions"
                  item-value="id"
                  item-text="name"
                  dense
                  outlined
                  hide-details="auto"
                  placeholder="Select Insurance"
                  :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                ></v-select>
                <RequestHint
                  v-if="request && request.insurance && !isApprovedRequest"
                  :is-attr-change="quotation.insurance_id !== request.insurance.id"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="request.insurance.name"
                />
              </v-col>
            </v-row>
          </v-card>

          <!-- Sum Insured -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="d-flex align-center"
              >
                <p class="mb-0">
                  Sum Insured
                </p>
              </v-col>
              <v-col
                cols="12"
                sm="3"
              >
                <v-text-field
                  v-model="quotation.sum_insured"
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details="auto"
                  prefix="$"
                  :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                ></v-text-field>
                <RequestHint
                  v-if="request && !isApprovedRequest"
                  :is-attr-change="quotation.sum_insured !== request.sum_insured"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="`$${formatCurrency(request.sum_insured)}`"
                />
              </v-col>
            </v-row>
          </v-card>

          <!-- Surplus Hibah -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="d-flex align-center"
              >
                <p class="mb-0">
                  Surplus Hibah
                </p>
              </v-col>
              <v-col
                cols="12"
                sm="3"
              >
                <v-text-field
                  v-model="quotation.surplus_hibah"
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details="auto"
                  prefix="$"
                  :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                ></v-text-field>
                <RequestHint
                  v-if="request && !isApprovedRequest"
                  :is-attr-change="quotation.surplus_hibah !== request.surplus_hibah"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="request.surplus_hibah ? `$${formatCurrency(request.surplus_hibah)}` : null"
                />
              </v-col>
            </v-row>
          </v-card>

          <!-- No Claim Discount -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="quotation.no_claim_discount"
                  dense
                  outlined
                  hide-details="auto"
                  placeholder="No Claim Dicsount %"
                  :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                ></v-text-field>
                <RequestHint
                  v-if="request && !isApprovedRequest"
                  :is-attr-change="quotation.no_claim_discount !== request.no_claim_discount"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="request.no_claim_discount"
                />
              </v-col>
            </v-row>
          </v-card>

          <!-- Period -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="quotation.period"
                  dense
                  outlined
                  hide-details="auto"
                  placeholder="Period (days)"
                  :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                ></v-text-field>
                <RequestHint
                  v-if="request && !isApprovedRequest"
                  :is-attr-change="quotation.period !== request.period"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="request.period"
                />
              </v-col>
            </v-row>
          </v-card>

          <!-- Insurance Premium -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="d-flex align-center"
              >
                <p class="mb-0">
                  Insurance Premium
                </p>
              </v-col>
              <v-col
                cols="12"
                sm="3"
              >
                <v-text-field
                  v-model="quotation.insurance_premium"
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details="auto"
                  prefix="$"
                  :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                ></v-text-field>
                <RequestHint
                  v-if="request && !isApprovedRequest"
                  :is-attr-change="quotation.insurance_premium !== request.insurance_premium"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="`$${formatCurrency(request.insurance_premium)}`"
                />
              </v-col>
            </v-row>
          </v-card>

          <!-- After Discount -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="d-flex align-center"
              >
                <p class="mb-0">
                  After Discount
                </p>
              </v-col>
              <v-col
                cols="12"
                offset-sm="3"
                sm="3"
              >
                <v-text-field
                  v-model="quotation.after_discount"
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details="auto"
                  prefix="$"
                  :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                ></v-text-field>
                <RequestHint
                  v-if="request && !isApprovedRequest"
                  :is-attr-change="quotation.after_discount !== request.after_discount"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="`$${formatCurrency(request.after_discount)}`"
                />
              </v-col>
            </v-row>
          </v-card>

          <!-- Road Tax Price -->
          <v-card
            v-if="isMotorInsurance"
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="d-flex align-center"
              >
                <p class="mb-0">
                  Road Tax Price
                </p>
              </v-col>
              <v-col
                cols="12"
                offset-sm="3"
                sm="3"
              >
                <v-text-field
                  v-model="quotation.road_tax"
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details="auto"
                  prefix="$"
                  :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                ></v-text-field>
                <RequestHint
                  v-if="request && !isApprovedRequest"
                  :is-attr-change="quotation.road_tax !== request.road_tax"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="request.road_tax ? `$${formatCurrency(request.road_tax)}` : null"
                />
              </v-col>
            </v-row>
          </v-card>

          <!-- Pickup Location -->
          <v-card
            v-if="isMotorInsurance"
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="quotation.pickup_location_id"
                  :items="pickupLocationOptions"
                  item-value="id"
                  item-text="name"
                  dense
                  outlined
                  hide-details="auto"
                  placeholder="Select Road Tax Pickup Location"
                  :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                ></v-select>
                <RequestHint
                  v-if="request && request.pickup_location && !isApprovedRequest"
                  :is-attr-change="quotation.pickup_location_id !== request.pickup_location.id"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="request.pickup_location.name"
                />

                <template>
                  <v-text-field
                    v-if="isOtherPickupLocation"
                    v-model="quotation.remarks.pickup_location"
                    single-line
                    dense
                    outlined
                    hide-details="auto"
                    placeholder="Enter Road Tax Pickup Location"
                    class="mt-3"
                    :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                  ></v-text-field>
                  <RequestHint
                    v-if="request && !isApprovedRequest"
                    :is-attr-change="quotation.remarks.pickup_location !== request.pickup_location_remarks"
                    :is-auditor-respond="auditorDisabled"
                    :is-request-void="isRequestVoid"
                    :hint="request.pickup_location_remarks"
                  />
                </template>
              </v-col>
            </v-row>
          </v-card>

          <!-- Additional Fees -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="d-flex align-center"
              >
                <p class="mb-0">
                  Additional Fees
                </p>
              </v-col>
              <v-col
                cols="12"
                offset-sm="3"
                sm="3"
              >
                <v-text-field
                  v-model="quotation.additional_fees"
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details="auto"
                  prefix="$"
                  :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                ></v-text-field>
                <RequestHint
                  v-if="request && !isApprovedRequest"
                  :is-attr-change="quotation.additional_fees !== request.additional_fees"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="request.additional_fees ? `$${formatCurrency(request.additional_fees)}` : null"
                />
              </v-col>
            </v-row>
          </v-card>

          <!-- General remarks -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="quotation.remarks.general"
                  dense
                  outlined
                  hide-details="auto"
                  placeholder="General Remarks"
                  :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                ></v-text-field>
                <RequestHint
                  v-if="request && !isApprovedRequest"
                  :is-attr-change="quotation.remarks.general !== request.general_remarks"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="request.general_remarks"
                />
              </v-col>
            </v-row>
          </v-card>
        </div>

        <v-divider></v-divider>

        <!-- Quotation Footer -->
        <div class="py-5 px-5">
          <!-- Issuer -->
          <v-row>
            <v-col
              cols="12"
              sm="6"
              class="d-flex align-center"
            >
              <p class="mb-0 pr-2 font-weight-semibold">
                Issued By:
              </p>
              <p class="mb-0">
                {{ quotation.issuer }}
              </p>
            </v-col>

            <v-col
              cols="4"
              sm="3"
              class="d-flex align-center justify-end"
            >
              <p class="mb-0 font-weight-semibold">
                Total:
              </p>
            </v-col>

            <!-- Total -->
            <v-col
              cols="8"
              sm="3"
              :class="{ 'px-0': $vuetify.breakpoint.xsOnly }"
            >
              <p class="mb-0 text-xl font-weight-semibold">
                ${{ calculatedTotal }}
              </p>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      md="3"
    >
      <v-card
        v-if="!isApprovedRequest"
        class="mb-6"
      >
        <v-card-text>
          <!-- Auditor view request -->
          <v-btn
            v-if="can('write', 'ReportActions') && requestId"
            color="primary"
            depressed
            block
            class="mb-2"
            :disabled="isRespondedRequest || quotation.deleted_at"
            @click="isApproveDialogVisible = true"
          >
            {{ t('Approve Request') }}
          </v-btn>

          <!-- Auditor view request -->
          <v-btn
            v-if="can('write', 'ReportActions') && requestId"
            color="primary"
            outlined
            depressed
            block
            :disabled="isRespondedRequest"
            @click="isRejectDialogVisible = true"
          >
            {{ t('Reject Request') }}
          </v-btn>

          <!-- Create & edit quotation -->
          <v-btn
            v-else-if="can('write', 'ReportActions')"
            color="primary"
            depressed
            block
            :loading="loading"
            :disabled="loading"
            @click="updateQuotation()"
          >
            {{ t('Confirm Quotation') }}
          </v-btn>

          <!-- Void request -->
          <v-btn
            v-else-if="router.currentRoute.params.action === 'Void' || quotation.deleted_at"
            color="error"
            depressed
            block
            :disabled="isApprovedRequest"
            @click="isVoidDialogVisible = true"
          >
            {{ t('Remove Request') }}
          </v-btn>

          <!-- Create & edit request -->
          <v-btn
            v-else
            color="primary"
            depressed
            block
            :loading="loading"
            :disabled="loading || isApprovedRequest"
            @click="isCommentDialogVisible = true"
          >
            {{ t('Send Request') }}
          </v-btn>

          <div
            v-if="quotation.deleted_at"
            class="text-center mt-4"
          >
            {{ can('write', 'ReportActions') ? t('Quotation has been voided, please inform requester to remove this request.')
              : t('Quotation has been voided, please remove this request as it cannot be saved.') }}
          </div>

          <div
            v-else-if="!auditorDisabled && router.currentRoute.params.action !== 'Void'"
            class="text-center mt-4"
          >
            {{ t('Please ensure that all fields are filled correctly as this record will not be editable after confirming.') }}
          </div>
        </v-card-text>
      </v-card>
      <v-card
        v-if="errors.length != 0"
        class="mb-6"
      >
        <v-card-text>
          <Alert
            :messages="errors"
            color="error"
          ></Alert>
        </v-card-text>
      </v-card>
      <v-card
        v-if="requestId && !isApprovedRequest && can('write', 'ReportActions')"
        class="mb-6"
      >
        <v-card-text v-if="request.requested_by">
          <div v-if="isRequestVoid">
            {{ request.requested_by.nickname }} has request to void this quotation.
          </div>
          <div v-else>
            <div class="mb-2">
              {{ request.requested_by.nickname }} has request to edit this quotation.
            </div>
            <div>The <span class="primary--text font-weight-semibold">requested changes</span> is hinted below each quotation field, ensure these changes are appropriate as this quotation will not be reversible after approval.</div>
          </div>
        </v-card-text>
      </v-card>
      <v-card
        v-if="isApprovedRequest"
        class="mb-6"
      >
        <v-card-text>
          <div>
            This request has been approved & no longer editable.
          </div>
        </v-card-text>
      </v-card>
      <RequestComments
        v-if="requestId"
        :comments="comments"
        :fetch-comments="fetchComments"
      />
    </v-col>

    <!-- Void dialog -->
    <v-dialog
      v-model="isVoidDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4">
          Void request on {{ request.record_number || quotation.quotation_number }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isVoidDialogVisible = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          This request will be voided. It won't be taken into any calculations.
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="error"
            block
            :loading="voidLoading"
            :disabled="voidLoading"
            class="mt-3"
            @click="voidRequest()"
          >
            Yes, remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Comment dialog -->
    <v-dialog
      v-model="isCommentDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4">
          Request changes?
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isCommentDialogVisible = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-textarea
            v-model="newComment"
            dense
            outlined
            hide-details="auto"
            placeholder="Leave your comment here (optional)"
            :disabled="loading"
            class="mb-4"
          ></v-textarea>
          <p>
            {{ requestId ? 'This updates the edit request & changes status to Pending for auditor to respond.' :
              'This action will create an edit request & the changes will be saved after approved by auditor.' }}
          </p>
          <p class="mb-0">
            Please leave any remarks that the auditor should know about the changes.
          </p>
        </v-card-text>

        <v-card-actions class="mt-2">
          <v-btn
            color="primary"
            block
            :loading="loading"
            :disabled="loading"
            @click="saveRequest()"
          >
            Yes, send request
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Approve dialog -->
    <v-dialog
      v-model="isApproveDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4">
          Approve request
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isApproveDialogVisible = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="newComment"
            dense
            outlined
            hide-details="auto"
            placeholder="Leave your comment here (optional)"
            :disabled="loading"
            class="mb-1"
          ></v-text-field>
          This action will update quotation with the request changes, ensure that all fields are filled correctly as this record will not be editable after approval.
        </v-card-text>

        <v-card-actions class="mt-2">
          <v-btn
            color="primary"
            block
            :loading="loading"
            :disabled="loading"
            @click="approveRequest()"
          >
            Save quotation
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Reject dialog -->
    <v-dialog
      v-model="isRejectDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4">
          Reject request
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isRejectDialogVisible = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="newComment"
            dense
            outlined
            hide-details="auto"
            placeholder="Leave your comment here (optional)"
            :disabled="loading"
            class="mb-1"
          ></v-text-field>
          This update the request & change status to Rejected, comment on the reason for rejecting the requested change.
        </v-card-text>

        <v-card-actions class="mt-2">
          <v-btn
            color="primary"
            block
            :loading="loading"
            :disabled="loading"
            @click="rejectRequest()"
          >
            Reject request
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mdiClose } from '@mdi/js'
import {
  inject,
  onUnmounted,
  onMounted,
  computed,
  ref,
} from '@vue/composition-api'
import { useRouter } from '@core/utils'
import { Letterhead } from '@/components/apps/previews'
import RequestHint from '@/components/apps/request/RequestHint'
import RequestComments from '@/components/apps/request/RequestComments'
import Alert from '@/components/Alert'
import quotationStoreModule from './quotationStoreModule'

export default {
  components: {
    Letterhead,
    Alert,
    RequestHint,
    RequestComments,
  },
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const t = inject('t')
    const can = inject('can')
    const { router } = useRouter()
    const requestId = router.currentRoute.params.request_id
    const QUOTATION_APP_STORE_MODULE_NAME = 'app-quotation'

    if (!store.hasModule(QUOTATION_APP_STORE_MODULE_NAME)) {
      store.registerModule(QUOTATION_APP_STORE_MODULE_NAME, quotationStoreModule)
    }

    // Properties
    const quotation = ref({
      created_at: null,
      company_name: null,
      insurance_product_id: null,
      car_registration_number: null,
      insurance_id: null,
      pickup_location_id: null,
      no_claim_discount: null,
      period: null,
      sum_insured: null,
      surplus_hibah: null,
      insurance_premium: null,
      after_discount: null,
      road_tax: null,
      additional_fees: null,
      remarks: {
        general: null,
        pickup_location: null,
      },
      issuer: null,
    })
    const request = ref({})
    const comments = ref([])
    const newComment = ref(null)
    const errors = ref([])
    const userData = JSON.parse(localStorage.getItem('userData'))
    const insuranceProductOptions = ref([])
    const insuranceOptions = ref([])
    const pickupLocationOptions = ref([])
    const motorInsuranceId = ref(null)
    const otherPickupLocationId = ref(null)
    const loading = ref(false)
    const voidLoading = ref(false)
    const auditorDisabled = ref(false)
    const voidRequestDisabled = ref(false)
    const isVoidDialogVisible = ref(false)
    const isCommentDialogVisible = ref(false)
    const isApproveDialogVisible = ref(false)
    const isRejectDialogVisible = ref(false)
    const isRequestVoid = ref(false)
    const isApprovedRequest = ref(false)
    const isRespondedRequest = ref(false)

    // Methods
    const parseCurrency = value => (value === null ? null : value.replace(/[$,]/g, ''))
    const formatCurrency = value => (parseFloat(value)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    const fetchInsuranceProducts = () => {
      store
        .dispatch('app-quotation/fetchInsuranceProducts')
        .then(response => {
          const { data } = response.data
          insuranceProductOptions.value = data
          motorInsuranceId.value = insuranceProductOptions.value.filter(product => product.name === 'Motor insurance')[0].id
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching insurance products. Please refresh!')
        })
    }
    const fetchInsurances = () => {
      store
        .dispatch('app-quotation/fetchInsurances')
        .then(response => {
          const { data } = response.data
          insuranceOptions.value = data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching insurances. Please refresh!')
        })
    }
    const fetchPickupLocations = () => {
      store
        .dispatch('app-quotation/fetchPickupLocations')
        .then(response => {
          const { data } = response.data
          pickupLocationOptions.value = data
          otherPickupLocationId.value = pickupLocationOptions.value.filter(pickup => pickup.name === 'Others')[0].id
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching pickup locations. Please refresh!')
        })
    }
    const fetchQuotation = () => {
      store
        .dispatch('app-quotation/fetchQuotation', { id: router.currentRoute.params.id })
        .then(response => {
          const existingQuotation = response.data.data

          quotation.value.id = existingQuotation.id
          quotation.value.created_at = existingQuotation.created_at
          quotation.value.branch_id = existingQuotation.branch.id
          quotation.value.company_name = existingQuotation.company_name
          quotation.value.insurance_product_id = existingQuotation.insurance_product.id
          quotation.value.car_registration_number = existingQuotation.car_registration_number
          quotation.value.insurance_id = existingQuotation.insurance.id
          quotation.value.bluecard_finance_id = existingQuotation.bluecard_finance ? existingQuotation.bluecard_finance.id : null
          quotation.value.pickup_location_id = existingQuotation.pickup_location ? existingQuotation.pickup_location.id : null
          quotation.value.no_claim_discount = existingQuotation.no_claim_discount
          quotation.value.period = existingQuotation.period
          quotation.value.sum_insured = parseCurrency(existingQuotation.sum_insured)
          quotation.value.surplus_hibah = parseCurrency(existingQuotation.surplus_hibah)
          quotation.value.insurance_premium = parseCurrency(existingQuotation.insurance_premium)
          quotation.value.after_discount = parseCurrency(existingQuotation.after_discount)
          quotation.value.road_tax = parseCurrency(existingQuotation.road_tax)
          quotation.value.additional_fees = parseCurrency(existingQuotation.additional_fees)
          quotation.value.remarks.general = existingQuotation.general_remarks
          quotation.value.remarks.bluecard_finance = existingQuotation.bluecard_finance_remarks
          quotation.value.remarks.pickup_location = existingQuotation.pickup_location_remarks
          quotation.value.issuer = existingQuotation.issuer.nickname
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching quotation. Please refresh!')
        })
    }
    const updateQuotation = () => {
      store
        .dispatch('app-quotation/updateQuotation', { id: router.currentRoute.params.id, quotation: quotation.value })
        .then(response => {
          const { data } = response.data
          router.push(`/apps/quotation/preview/${data.id}`)
          loading.value = false
        })
        .catch(error => {
          loading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while updating quotation. Please refresh!')
        })
    }
    const createComment = id => {
      store
        .dispatch('requestStore/createComment', {
          user_id: userData.id,
          approval_request_id: id || requestId,
          comment: newComment.value,
        })
        .then(response => {
          snackbarService.success(response.data.message)
          router.push('/apps/requests/list')
        })
        .catch(error => {
          loading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while saving comment. Please refresh!')
        })
    }
    const reassignEmptyString = () => {
      const attributes = ['surplus_hibah', 'additional_fees', 'no_claim_discount', 'period', 'road_tax']
      attributes.forEach(attr => {
        if (quotation.value[attr] === null) quotation.value[attr] = ''
      })
    }
    const saveRequest = () => {
      loading.value = true
      delete quotation.value.issuer
      reassignEmptyString()
      const requestParam = {
        record_id: quotation.value.id,
        record_type: 'Quotation',
        requested_by_id: userData.id,
        action: 'Edit',
        params: quotation.value,
        rejected_at: null,
        responded_by: null,
        comment: newComment.value,
      }
      if (requestId) requestParam.id = requestId
      store
        .dispatch(`requestStore/${requestId ? 'updateRequest' : 'createRequest'}`, requestParam)
        .then(response => {
          if (newComment.value) {
            createComment(response.data.data.id)
          } else {
            snackbarService.success(response.data.message)
            router.push('/apps/requests/list')
          }
        })
        .catch(error => {
          loading.value = false
          isCommentDialogVisible.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while saving request. Please refresh!')
        })
    }
    const fetchRequest = () => {
      store
        .dispatch('requestStore/fetchRequest', { id: requestId })
        .then(response => {
          let quotationAttrsInField = response.data.data.record
          if (response.data.data.approved_at) {
            isApprovedRequest.value = true
            quotationAttrsInField = response.data.data.result
          }
          const existingQuotation = quotationAttrsInField
          quotation.value.id = response.data.data.record.id
          quotation.value.created_at = existingQuotation.created_at
          quotation.value.branch_id = existingQuotation.branch.id
          quotation.value.company_name = existingQuotation.company_name
          quotation.value.insurance_product_id = existingQuotation.insurance_product.id
          quotation.value.car_registration_number = existingQuotation.car_registration_number
          quotation.value.insurance_id = existingQuotation.insurance.id
          quotation.value.bluecard_finance_id = existingQuotation.bluecard_finance ? existingQuotation.bluecard_finance.id : null
          quotation.value.pickup_location_id = existingQuotation.pickup_location ? existingQuotation.pickup_location.id : null
          quotation.value.no_claim_discount = existingQuotation.no_claim_discount
          quotation.value.period = existingQuotation.period
          quotation.value.sum_insured = parseCurrency(existingQuotation.sum_insured)
          quotation.value.surplus_hibah = parseCurrency(existingQuotation.surplus_hibah)
          quotation.value.insurance_premium = parseCurrency(existingQuotation.insurance_premium)
          quotation.value.after_discount = parseCurrency(existingQuotation.after_discount)
          quotation.value.road_tax = parseCurrency(existingQuotation.road_tax)
          quotation.value.additional_fees = parseCurrency(existingQuotation.additional_fees)
          quotation.value.remarks.general = existingQuotation.general_remarks
          quotation.value.remarks.bluecard_finance = existingQuotation.bluecard_finance_remarks
          quotation.value.remarks.pickup_location = existingQuotation.pickup_location_remarks
          quotation.value.issuer = existingQuotation.issuer.nickname
          quotation.value.deleted_at = existingQuotation.deleted_at

          if (response.data.data.result) {
            request.value = response.data.data.result
            request.value.sum_insured = parseCurrency(request.value.sum_insured)
            request.value.surplus_hibah = parseCurrency(request.value.surplus_hibah)
            request.value.insurance_premium = parseCurrency(request.value.insurance_premium)
            request.value.after_discount = parseCurrency(request.value.after_discount)
            request.value.road_tax = parseCurrency(request.value.road_tax)
            request.value.additional_fees = parseCurrency(request.value.additional_fees)
          }
          request.value.requested_by = response.data.data.requested_by
          request.value.record_number = response.data.data.record.quotation_number
          isRequestVoid.value = response.data.data.action === 'Void'
          if (response.data.data.responded_by) isRespondedRequest.value = true
          if (response.data.data.approved_at) isApprovedRequest.value = true
        })
        .catch(error => {
          loading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching request. Please refresh!')
        })
    }
    const fetchRequestedQuotation = () => {
      store
        .dispatch('requestStore/fetchRequest', { id: requestId })
        .then(response => {
          const returnedQuotation = response.data.data.result

          quotation.value.id = response.data.data.record.id
          quotation.value.created_at = returnedQuotation.created_at
          quotation.value.quotation_number = returnedQuotation.quotation_number
          quotation.value.branch_id = returnedQuotation.branch.id
          quotation.value.company_name = returnedQuotation.company_name
          quotation.value.insurance_product_id = returnedQuotation.insurance_product.id
          quotation.value.car_registration_number = returnedQuotation.car_registration_number
          quotation.value.insurance_id = returnedQuotation.insurance.id
          quotation.value.bluecard_finance_id = returnedQuotation.bluecard_finance ? returnedQuotation.bluecard_finance.id : null
          quotation.value.pickup_location_id = returnedQuotation.pickup_location ? returnedQuotation.pickup_location.id : null
          quotation.value.no_claim_discount = returnedQuotation.no_claim_discount
          quotation.value.period = returnedQuotation.period
          quotation.value.sum_insured = parseCurrency(returnedQuotation.sum_insured)
          quotation.value.surplus_hibah = parseCurrency(returnedQuotation.surplus_hibah)
          quotation.value.insurance_premium = parseCurrency(returnedQuotation.insurance_premium)
          quotation.value.after_discount = parseCurrency(returnedQuotation.after_discount)
          quotation.value.road_tax = parseCurrency(returnedQuotation.road_tax)
          quotation.value.additional_fees = parseCurrency(returnedQuotation.additional_fees)
          quotation.value.remarks.general = returnedQuotation.general_remarks
          quotation.value.remarks.bluecard_finance = returnedQuotation.bluecard_finance_remarks
          quotation.value.remarks.pickup_location = returnedQuotation.pickup_location_remarks
          quotation.value.issuer = returnedQuotation.issuer.nickname
          if (response.data.data.approved_at) isApprovedRequest.value = true
          quotation.value.deleted_at = returnedQuotation.deleted_at
        })
        .catch(error => {
          loading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching request. Please refresh!')
        })
    }
    const approveRequest = () => {
      loading.value = true
      store
        .dispatch('requestStore/approveRequest', { id: requestId, comment: newComment.value })
        .then(response => {
          if (newComment.value) {
            createComment()
          } else {
            snackbarService.success(response.data.message)
            router.push('/apps/requests/list')
          }
        })
        .catch(error => {
          loading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while approving request. Please refresh!')
        })
    }
    const rejectRequest = () => {
      loading.value = true
      store
        .dispatch('requestStore/rejectRequest', { id: requestId, comment: newComment.value })
        .then(response => {
          if (newComment.value) {
            createComment()
          } else {
            snackbarService.success(response.data.message)
            router.push('/apps/requests/list')
          }
        })
        .catch(error => {
          loading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while rejecting request. Please refresh!')
        })
    }
    const voidRequest = () => {
      voidLoading.value = true
      store
        .dispatch('requestStore/voidRequest', { id: requestId })
        .then(response => {
          snackbarService.success(response.data.message)
          router.push('/apps/requests/list')
        })
        .catch(error => {
          voidLoading.value = false
          snackbarService.error(error.data.message || 'Something went wrong while voiding request. Please refresh!')
        })
    }
    const fetchComments = () => {
      store
        .dispatch('requestStore/fetchComments', { request_id: requestId })
        .then(response => {
          const { data } = response.data
          comments.value = data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching comments. Please refresh!')
        })
    }
    const isQuotationEdit = () => {
      if (requestId && can('write', 'ReportActions')) {
        // Auditor view request
        auditorDisabled.value = true
        fetchRequest()
      } else if (router.currentRoute.params.action === 'Void') {
        voidRequestDisabled.value = true
        fetchRequest()
      } else if (requestId) {
        // Clerk edit request
        fetchRequestedQuotation()
      } else {
        // Edit quotation / create request
        fetchQuotation()
      }
    }

    // Computed
    const branchOptions = computed(() => store.state.branches.filter(b => b.active))
    const isMotorInsurance = computed(() => (motorInsuranceId.value === quotation.value.insurance_product_id))
    const isOtherPickupLocation = computed(() => {
      if (otherPickupLocationId.value !== quotation.value.pickup_location_id) quotation.value.remarks.pickup_location = null

      return otherPickupLocationId.value === quotation.value.pickup_location_id
    })
    const calculatedTotal = computed(() => {
      const afterDiscount = parseFloat(quotation.value.after_discount) || 0.00
      const roadTax = parseFloat(quotation.value.road_tax) || 0.00
      const additionalFees = parseFloat(quotation.value.additional_fees) || 0.00

      return parseFloat(afterDiscount + roadTax + additionalFees).toFixed(2)
    })

    // Mounted
    onMounted(() => {
      if (requestId) fetchComments()
      isQuotationEdit()
      fetchInsuranceProducts()
      fetchInsurances()
      fetchPickupLocations()
    })

    // Unmounted
    onUnmounted(() => {
      if (store.hasModule(QUOTATION_APP_STORE_MODULE_NAME)) store.unregisterModule(QUOTATION_APP_STORE_MODULE_NAME)
    })

    return {
      t,
      can,
      router,
      quotation,
      errors,
      branchOptions,
      insuranceProductOptions,
      insuranceOptions,
      pickupLocationOptions,
      isMotorInsurance,
      isOtherPickupLocation,
      calculatedTotal,
      updateQuotation,
      loading,
      voidLoading,
      auditorDisabled,
      voidRequestDisabled,
      isRequestVoid,
      isApprovedRequest,
      isRespondedRequest,
      formatCurrency,
      request,
      saveRequest,
      approveRequest,
      rejectRequest,
      voidRequest,
      requestId,
      newComment,
      comments,
      fetchComments,
      isVoidDialogVisible,
      isCommentDialogVisible,
      isApproveDialogVisible,
      isRejectDialogVisible,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/style.scss';

.app-quotation-editable {
  .quotation-add-branch {
    width: 15rem;
  }
}
</style>
